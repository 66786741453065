import axios from "axios";
import toast from "react-hot-toast";

function CustomButton({ click, placeHolder, style }) {
    const buttonStyle = {
        textAlign: "center",
        cursor: "pointer",
        ...style,
    };

    return (
        <div style={buttonStyle} onClick={click}>
            {placeHolder}
        </div>
    );
}

function NewItemConfirmModal({
    closeModal,
    setmodalState,
    newField,
    confirmURL,
    setLoading,
    showAlert,
    setProgress,
    bulkUploadId,
}) {
    const confirm = (selection) => {
        setLoading(true);
        const config = {
            onUploadProgress: (progressEvent) => {
                const progress = Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total
                );
                setProgress(progress);
            },
        };
        axios
            .post(
                confirmURL,
                {
                    proceed: selection,
                    bulkUploadId: bulkUploadId
                },
                config
            )
            .then((res) => {
                setLoading(false);
                if (selection == "no") {
                    // toast.success(res.data.statusText);
                    showAlert("success", res.data.statusText);
                } else {
                    setmodalState("success");
                }
            })
            .catch((error) => {
                // toast.error(error.response.data.statusText);
                showAlert("error", error.response.data.statusText);
                setLoading(false);
            });
    };

    return (
        <div>
            <div
                style={{
                    fontWeight: "600",
                    fontSize: "22px",
                    lineHeight: "27.57px",
                    paddingBottom: "20px",
                    color: "#192434",
                }}
            >
                New {newField} Found
            </div>
            <div
                style={{
                    fontWeight: "400",
                    fontSize: "16px",
                    lineHeight: "25.78px",
                    paddingBottom: "50px",
                }}
            >
                A new {newField} has been identified in your sheet. Would you
                like to add it to the {newField} list ?
            </div>
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
            >
                <CustomButton
                    placeHolder="Cancel"
                    style={{ color: "#005FDB" }}
                    click={() => {
                        confirm("no");
                        showAlert(
                            "success",
                            "User opted not to proceed with the changes"
                        );
                        closeModal();
                    }}
                />

                <CustomButton
                    placeHolder="Save & Continue"
                    style={{
                        border: "1px solid #D0D5DD",
                        padding: "10px",
                        borderRadius: "8px",
                        background: "#006AF5",
                        color: "#FFFFFF",
                    }}
                    click={() => {
                        confirm("yes");
                    }}
                />
            </div>
        </div>
    );
}

export default NewItemConfirmModal;
