import AlumniDetails from '../views/alumniDetails/AlumniDetails';
import AlumniLookingForJob from '../views/alumniLookingForJob/AlumniLookingForJob';
import AlumniPersonalTag from '../views/alumniPersonalTag/AlumniPersonalTag';
import FacultyPersonalTag from '../views/facultyPersonalTag/FacultyPersonalTag.js';
import StudentPersonalTag from '../views/studentPersonalTag/StudentPersonalTag.js';

import Announcements from '../views/announcements/Announcements';
import Batches from '../views/batches/Batches';
import BatchHeads from '../views/batchHeads/BatchHeads';
import CityChapterAlumniMap from '../views/cityChapterAlumniMap/CityChapterAlumniMap';
import CityChapterHead from '../views/cityChapterHead/CityChapterHead';
import CityChapters from '../views/cityChapters/CityChapters';
// import CovidVaccineStatus from "../views/covidVaccineStatus/CovidVaccineStatus";
import Dashboard from '../views/dashboard/Dashboard';
import EventCalendar from '../views/eventCalendar/EventCalendar';
import GoverningCouncil from '../views/governingCouncil/GoverningCouncil';
import HelpRequests from '../views/helpRequests/HelpRequests';
import BenefitProviders from '../views/benefitProviders/BenefitProviders';
import MarkAsSafe from '../views/markAsSafe/MarkAsSafe.js';
import PersonalTags from '../views/personalTags/PersonalTags';
import PostedJobs from '../views/postedJobs/PostedJobs';
import RSVP from '../views/eventCalendar/RSVP';
import SafetyResponse from '../views/safetyResponse/SafetyResponse';
import TopicOfInterest from '../views/topicOfInterest/TopicOfInterest';
import BenefitDetails from '../views/insuranceDetails/BenefitDetails';
import Courses from '../views/courses/Courses';
import StudentDetails from '../views/studentDetails/StudentDetails';
import FacultyDetails from '../views/facultyDetails/FacultyDetails';
import Institution from '../views/superAdmin/institution/Institution';
import InstituteMemberDetails from '../views/instituteMemberDetails/InstituteMemberDetails';
import InstituteDashboard from '../views/instituteMemberLogin/Dashboard.js';
import StudentCouncil from '../views/studentCouncil/StudentCouncil.js';
import ClassRep from '../views/classRepresentative/ClassRepresentative.js';
import StudentCouncilDashboard from '../views/studentCouncilLogin/Dashboard.js';
// import SCMarkAsSafe from '../';
import CityChapterDashBoard from '../views/cityChapterLogin/Dashboard.js';

import BatchRepDashboard from '../views/batchRepLogin/Dashboard.js';
import SCMarkAsSafe from '../views/scMarkSafe/MarkAsSafe.js';
// import ClassRepDashBoard from '../views/classRepDashboard/DashboardIndex.js';
import ClassRepDashBoard from '../views/classRepLoginNew/Dashboard.js';

import StudentLookingForJob from '../views/studentLookigForJob/StudentLookingForJob.js';
import Promos from '../views/promos/Promos.js';
import BloodDonor from '../views/bloodDonor/BloodDonor.js';
import BatchWiseAlumniMap from '../views/batchWiseAlumniMap/BatchWiseAlumniMap.js';
import PostsReports from '../views/reportedPosts/Reports.js';
import ReportList from '../views/reportedPosts/ReportLists.js';
import BlockedReportLists from '../views/reportedPosts/BlockedReportLists.js';
import ReportReason from '../views/reportReason/ReportReason.js';


var routes = [
    {
        path: 'institute-member-dashboard',
        name: 'InstituteDashboard',
        page: InstituteDashboard,
        text: 'Dashboard',
        notOnMenu: true,
    },
    {
        path: '',
        name: 'Dashboard',
        page: Dashboard,
    },
    {
        path: 'student-council-dashboard',
        name: 'student-council-Dashboard',
        text: 'Dashboard',
        page: StudentCouncilDashboard,
        notOnMenu: true,
    },
    {
        path: 'batchRep-dashboard',
        name: 'batchRep-Dashboard',
        text: 'Dashboard',
        page: BatchRepDashboard,
        notOnMenu: true,
    },

    {
        path: 'cityChapterdashboard',
        name: 'City Chapter Dashboard',
        text: 'Dashboard',
        page: CityChapterDashBoard,
        notOnMenu: true,
    },

    {
        path: 'alumni-details',
        name: 'Alumni Details',
        page: AlumniDetails,
    },
    {
        path: 'classrepdashboard',
        name: 'Class Rep Dashboard',
        text: 'Dashboard',
        page: ClassRepDashBoard,
        notOnMenu: true,
    },
    {
        path: 'student-details',
        name: 'Student Details',
        page: StudentDetails,
    },
    {
        path: 'faculty-details',
        name: 'Faculty Details',
        page: FacultyDetails,
    },
    // {
    //     path: 'institute-member-details',
    //     name: 'Institute Member Details',
    //     page: InstituteMemberDetails,
    // },
    // {
    //     path: 'institute-member-details',
    //     name: 'Institute Member Details',
    //     page: InstituteMemberDetails,
    //     hiddenFromMenu: true,
    // },
    {
        path: 'alumni-personaltag',
        name: 'Alumni Personal Tag Mapping',
        page: AlumniPersonalTag,
    },
    {
        path: 'student-personaltag',
        name: 'Student Personal Tag Mapping',
        page: StudentPersonalTag,
    },
    {
        path: 'faculty-personaltag',
        name: 'Faculty Personal Tag Mapping',
        page: FacultyPersonalTag,
    },

    {
        path: 'city-chapters',
        name: 'City Chapters',
        page: CityChapters,
    },
    {
        path: 'city-chapter-map',
        name: 'City Chapters Alumni Mapping',
        page: CityChapterAlumniMap,
        hiddenFromMenu: true,
        notOnMenu: true,
    },
    {
        path: 'events',
        name: 'Event Calendar',
        page: EventCalendar,
    },
    {
        path: 'rsvp',
        name: 'RSVP',
        page: RSVP,
        hiddenFromMenu: true,
        notOnMenu: true,
    },
    {
        path: 'announcements',
        name: 'Announcements',
        page: Announcements,
    },
    {
        path: 'job-seekers',
        name: 'Member Looking for job',
        page: AlumniLookingForJob,
    },

    {
        path: 'mark-as-safe',
        name: 'Mark as Safe Events',
        page: MarkAsSafe,
    },
    {
        path: 'sc-mark-as-safe',
        name: 'Mark as Safe Events',
        page: SCMarkAsSafe,
    },
    {
        path: 'safety-response',
        name: 'Safety Response',
        page: SafetyResponse,
    },
    {
        path: 'promos',
        name: 'Promos',
        page: Promos,
    },
    {
        path: 'posted-jobs',
        name: 'Posted Jobs',
        page: PostedJobs,
    },

    {
        path: 'student-job-seekers',
        name: 'Student Looking for job',
        page: StudentLookingForJob,
    },
    {
        path: 'help-requests',
        name: 'Help Requests',
        page: HelpRequests,
    },

    {
        path: 'benefit-details',
        name: 'Benefit Details',
        page: BenefitDetails,
        // module: 'Benefit Details'
    },
    // {
    //     path: 'post-reports',
    //     name: 'Reported Posts',
    //     page: PostsReports,
    // },
    // {
    //     path: 'vaccine-details',
    //     name: 'Vaccine Status',
    //     page: CovidVaccineStatus,
    // },
    {
        path: 'batch',
        name: 'Batch',
        page: Batches,
        module: 'enum',
    },
    {
        path: 'alumini-map-list',
        name: 'Batch Wise Alumni Mapping',
        page: BatchWiseAlumniMap,
        hiddenFromMenu: true,
        notOnMenu: true,
    },
    {
        path: 'batch-head',
        name: 'Batch Representatives',
        page: BatchHeads,
        module: 'enum',
    },
    {
        path: 'gc-council',
        name: 'Governing Council',
        page: GoverningCouncil,
        module: 'enum',
    },
    {
        path: 'city-chapter-head',
        name: 'City Chapter Head',
        page: CityChapterHead,
        module: 'enum',
    },
    {
        path: 'courses',
        name: 'Courses',
        page: Courses,
        module: 'enum',
    },
    {
        path: 'personal-tags',
        name: 'Personal Tags',
        page: PersonalTags,
        module: 'enum',
    },
    {
        path: 'topic-of-interest',
        name: 'Topic of Interest',
        page: TopicOfInterest,
        module: 'enum',
    },
    {
        path: 'insurance-providers',
        name: 'Benefit Providers',
        page: BenefitProviders,
        module: 'enum',
    },
    {
        path: 'student-council',
        name: 'Student Council',
        page: StudentCouncil,
        module: 'enum',
    },
    {
        path: 'class-representative',
        name: 'Class Representative',
        page: ClassRep,
        module: 'enum',
    },
    {
        path: 'blood-donor',
        name: 'Blood Donor',
        page: BloodDonor,
    },
    {
        path: 'blood-donor-admin',
        name: 'Blood Donor',
        page: BloodDonor,
        module: 'enum',
    },
    {
        path: 'promos-admin',
        name: 'Promos',
        page: Promos,
        module: 'enum',
    },
    // {
    //     path: 'report-reasons',
    //     name: 'Report Reasons',
    //     page: ReportReason,
    //     module: 'enum',
    // },
    // {
    //     path: 'alumini-report-list',
    //     name: 'Report List',
    //     page: ReportList,
    //     hiddenFromMenu: true,
    //     notOnMenu: true,
    // },
    // {
    //     path: 'alumini-blocked-post-report-list',
    //     name: 'Blocked Posts Report List',
    //     page: BlockedReportLists,
    //     hiddenFromMenu: true,
    //     notOnMenu: true,
    // },

];
export default routes;
